


























































































































import { mapActions } from 'vuex';
import auth from '@/mixins/auth';

export default auth.extend({
  name: 'StaffDrawer',
  props: ['mini'],
  data() {
    return {
      drawer: false
    };
  },

  computed: {
    screenWidth() {
      return window.innerWidth > 600;
    }
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    gotoPage(routeName: string) {
      this.$router.push({ name: routeName });
    },
    open(value: boolean) {
      this.drawer = value;
    },
    goToLogout() {
      this.logout().finally(() => {
        this.$router.push({ name: 'login' });
      });
    }
  }
});
